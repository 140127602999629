@mixin __status {
  border-radius: 28px !important;
  padding: 4px 16px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

@mixin __status2 {
  @include __status;
  padding: 4px 12px;
  min-width: min-content;
  max-width: max-content;
}

.table {
  border: 2px solid #f5f5f5;
  border-radius: 8px;
}

.tablelight {
  background-color: #f1f2f2;
}

.tablerow {
  td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
  }
}

.headerCustom {
  span {
    overflow-x: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

.aqt {
  @include __status;
  border: 1px solid #53585f !important;
  color: #53585f !important;

  &:hover {
    color: white !important;
    background-color: #53585f;
  }
}

.pending {
  @include __status2;
  color: #eb5d57 !important;
  background-color: transparent;
  text-transform: capitalize !important;
  border: 1px solid #eb5d57
}

.nty {
  @include __status2;
  background-color: #dbdddf;
  color: #53585f;
}

.eoi {
  @include __status2;
  background-color: #fce7e6;
  color: #851001;
}

.nbi {
  @include __status2;
  background-color: #eb5d57;
  color: white;
}

.nr {
  @include __status2;
  background-color: transparent;
  border: 1px solid #53585f;
  color: #53585f;
}

.action {
  cursor: pointer;
}