.btnSearch {
  background-color: #830c0c !important;
}

@mixin __status {
  border-radius: 28px !important;
  padding: 6px 12px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.pending {
  @include __status;
  color: #eb5d57 !important;
  background-color: transparent;
  text-transform: capitalize !important;
  border: 1px solid #eb5d57;
  &.notCkeck {
    background-color: #a5a9a8;
    color: #a5a9a8;
    border: 1px solid #a5a9a8;
  }
}

.nty {
  @include __status;
  background-color: #dbdddf;
  color: #53585f;
  &.notCkeck {
    background-color: #a5a9a8;
    color: #a5a9a8;
    border: 1px solid #a5a9a8;
  }
}

.eoi {
  @include __status;
  background-color: #851001;
  color: #fce7e6;
  &.notCkeck {
    background-color: #a5a9a8;
    color: #a5a9a8;
    border: 1px solid #a5a9a8;
  }
}

.nbi {
  @include __status;
  background-color: #eb5d57;
  color: white;
  &.notCkeck {
    background-color: #a5a9a8;
    color: #a5a9a8;
    border: 1px solid #a5a9a8;
  }
}

.nr {
  @include __status;
  background-color: transparent;
  border: 1px solid #53585f;
  color: #53585f;
  &.notCkeck {
    background-color: #a5a9a8;
    color: #a5a9a8;
    border: 1px solid #a5a9a8;
  }
}
