@import "../../App.scss";

.actionRow {
  width: 140px !important;
}
.action {
  background-color: var(--Gris-moyen-clair) !important;
  border-color: var(--Gris-moyen-clair) !important;
  cursor: pointer !important;
  &:hover {
    color: #888 !important;
  }
}

.btn_reset {
  font-size: 30px;
  background-color: var(--Gris-moyen-clair) !important;
  transition: all 0.3s ease-in-out !important;
  cursor: pointer;
  &:hover {
    color: #888 !important;
    // font-size: 35px;
    // rotate infinite 1s linear;
    transform: rotate(360deg);
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.table {
  border: 2px solid #f5f5f5;
  border-radius: 8px;
}

.tablelight {
  background-color: var(--Gris-moyen-clair);
}

.tableWhite {
  background-color: #ffffff;
}

.tablerow {
  td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-weight: 300 !important;
  }
}

tr {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

