.nav-bar-popover .ant-popover-inner {
    background-color: var(--Rouge-vif) ;
}

.nav-bar-popover .ant-menu {
    background-color: var(--Rouge-vif);
    .ant-menu-title-content {
        color: white;
        font-size: larger;
    }
    .ant-menu-item-selected {
        background: transparent !important;
    }
}