.coloredRed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 32px;
  max-width: 65px;
  min-width: 65px;
  background: #eb5d57;
  border-radius: 20px;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.coloredGray {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 32px;
  max-width: 65px;
  min-width: 65px;
  background: #ffffff;
  /* Gris foncé moyen */
  border: 1px solid #a5a9a8;
  color: #a5a9a8;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 20px;
}

.coloredBorder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 32px;
  max-width: 65px;
  min-width: 65px;
  border: 1px solid #eb5d57;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 20px;
  color: #eb5d57;
}

.fixedMinHeaderId {
  max-width: 300px !important;
  min-width: 120px !important;
}

.fixedMinHeaderDate {
  max-width: 110px !important;
}

.fixedMinHeaderStatus {
  max-width: 100px !important;
}

.fixedMinHeaderRecepient {
  max-width: 120px !important;
}

.fixedMinHeaderAmount {
  max-width: 180px !important;
}

.fixedMinHeaderCurrency {
  max-width: 100px !important;
}

.fixedMinHeaderAmount,
.fixedMinHeaderCurrency,
.fixedMinHeaderRecepient,
.fixedMinHeaderStatus,
.fixedMinHeaderDate,
.fixedMinHeaderId {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}