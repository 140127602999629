
.rightSide.container {
    background-color: var(--Gris-clair) !important;
}

.dashboard-base-card {
    min-width: 203px;
    min-height: 95px;
    padding: 5px;
    border-radius: 16px;
    background: var(--Blanc);
    box-shadow: 1px 1px 4px 0px rgba(165, 169, 168, 0.50);
    .dashboard-alert-body {
        text-align: center;
        color: var(--Noir);
        font-size: 24px;
        margin-top: 6%;
        font-weight: 700;
    }

    .ant-typography {
        padding: 0!important;
        margin: 0!important;
    }

    .dashboad-timer {
        color: var(--Noir);
        .day {
            font-size: 20px;
            font-weight: 400;
        }
        h1 {
            font-size: 64px;
            font-weight: 700;
        }
        .time {
            font-size: 32px;
            font-weight: 400;
        }
    }

    .dashboard-title-center {
        color: var(--Noir);
        font-size: 16px;
        padding: 0;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .py-2 {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.base-card-title {
    color: var(--Rouge-bordeau);
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.dashboard-table-wrapper {
    box-shadow: 1px 1px 4px 0px rgba(165, 169, 168, 0.50);
    border-radius: 16px;
    padding: 0;
  
}
.dashboard-table .ant-table-tbody>tr>td {
    border: none;
    padding: 5px !important;
    font-size: 14px;
    height: 30px!important;
}

.dashboard-table {
    margin-top: 15px;
    .ant-table-container {
        border-start-start-radius: 0;
        border-start-end-radius: 0
    }
    .ant-table-measure-row {
        display: none;
    }
}

.dashboard-table .ant-table {
    border-radius: 0;
}

.ant-table-body {
    overflow: auto!important;
}

.dashboard-table .ant-table-body {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.dashboard-reminder-btn-active {
    border-radius: 8px;
    background: var(--Rouge-vif);
    border: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    padding: 8px;
    color: var(--Blanc)!important;
}

.dashboard-reminder-btn-inactive {
    border-radius: 8px;
    border: none;
    padding: 8px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 124px;
    line-height: 22px;
    background: var(--Gris-moyen-clair);
    color: var(--Gris-fonc)!important;
}

//Animation of reminder rows

@keyframes blink {
    25%,
    75% {
        color: var(--Rouge-vif);
        /* Adjust the color as needed */
    }
}

.blink-row {
    animation: blink 0.5s ease-in-out;
    animation-iteration-count: 20;
}

.blink-row-stop {
    color: var(--Rouge-vif);
}

.blink-row .MuiAvatar-circular {
    background: var(--Rouge-vif);
}

.blink-row-stop .MuiAvatar-circular {
    background: var(--Rouge-vif);
}

.black-icons .status-icons path {
    fill: var(--Noir);
    stroke: var(--Noir);
    stroke-width: 0.03
}



#dashboard-expiry-table .ant-table-body {
    height: 580px;
}

.agenda-past {
    color: var(--Gris-fonc-moyen);
}

.agenda-inprogress {
    color: var(--Gris-fonc)
}

.agenda-future {
    color: var(--Noir);
}


.headRightSide {
    padding-left: 308px;
}

@media only screen and (max-width: 1022px) {
    .headRightSide {
            padding-left: unset;
        }
    .dashboard-currencies {
        margin-top: 80px;
        margin-left: 10px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 683px) {
    .headRightSide {
        padding-left: unset;
    }

    .myAccount { margin-left: 250px;}

    .dashboard-currencies {
        margin-top: 0;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 1629px) {
    .dashboard-base-card {
        min-width: 180px;

        .dashboard-alert-body {
            font-size: 18px;
        }
    }

    .base-card-title,
    .dashboard-title-center {
        font-size: 14px !important;
    }

    #dashboard-whatsnew-table {
        height: 420px;
    }

    #dashboard-whatsnew-table .ant-table-body {
        height: 415px;
    }
}

@media only screen and (max-width: 1402px) {
    #dashboard-whatsnew-table {
        height: 400px;
    }

    #dashboard-whatsnew-table .ant-table-body {
        height: 395px;
    }
}

@media only screen and (max-width: 1221px) {
    #dashboard-whatsnew-table {
            height: 320px;
    }
    #dashboard-whatsnew-table .ant-table-body {
        height: 315px;
    }
}
