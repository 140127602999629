.btnCancel {
    background-color: #dbdddf !important;
  
    &:hover {
      background-color: #dbdddf !important;
      opacity: 0.9;
      color: #1d1d1d !important;
    }
  }
  
  .btnSave {
    background-color: #851001 !important;
    color: #fce7e6 !important;
  
    &:disabled {
      background-color: #fce7e6 !important;
      color: #f39e9a !important;
      opacity: 0.9 !important;
    }
  }