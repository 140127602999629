.fixedSizeHeaderId {
  width: 80px !important;
}

.fixedSizeHeaderTxt {
  width: 150px !important;
}

.fixedSizeHeaderObligor {
  width: 180px !important;
}