.coloredRed {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 32px;
  max-width: 65px;
  min-width: 65px;
  background: #EB5D57;
  border-radius: 20px;
  color: white;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.coloredGray {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 32px;
  max-width: 65px;
  min-width: 65px;
  background: #FFFFFF;
  /* Gris foncé moyen */
  border: 1px solid #A5A9A8;
  color: #A5A9A8;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  border-radius: 20px;
}

.coloredBorder {
  box-sizing: border-box;
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #EB5D57;
  flex-direction: row;
  padding: 0px;
  height: 32px;
  max-width: 90px;
  min-width: 90px;
  background: #FFFFFF;
  border: 1px solid #EB5D57;
  border-radius: 20px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}