.dropdown {
  &::before {
    content: "" !important;
  }
  margin-top: 8px !important;
  inset: 0px 0px auto auto !important;
  transform: translate(0px, 34px) !important;
  padding-top: 12px !important;
  padding-bottom: 0px !important;
  min-width: 220px;
}
.dropdownButton {
  place-items: center;
  background-color: #f1f2f2 !important;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: #b3b3b3;
  }
}
.dropClear{
    text-decoration: underline;
    cursor: pointer;
}
.dropFooter{
    border-top: 1px solid #f0f0f0 !important;
    background-color: #fefefe !important;
}
.dropdownItems{
    max-height: 220px;
    overflow-y: scroll ;
}
.dropdownItem {
    user-select: none;
    border: 1px solid #f39e9a;
    border-radius: 25px;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
    // &:hover {
    //   background-color: #f39e9a;
    //   color: white;
    // }
  }
  .dropdownItemHovered {
    background-color: #f39e9a;
    color: white;
  }
