.phase {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  height: 25px;
  background: #DBDDDF;
  border-radius: 20px;
  color: white;
  max-width: 65px;
}

.phaseColored {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  height: 25px;
  max-width: 65px;
  min-width: 65px;
  background: #EB5D57;
  border-radius: 20px;
  color: white;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.phaseColoredLead {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  gap: 10px;
  height: 25px;
  max-width: 65px;
  min-width: 65px;
  background: #FCE7E6;
  border-radius: 20px;
  color: #851001;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.phaseColoredOffert {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 0px;
  gap: 10px;
  height: 25px;
  max-width: 65px;
  min-width: 65px;
  background: #F39E9A;
  border-radius: 20px;
  color: white;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.all {
  font-weight: 600;
  padding: 1.5px 10px;
  font-size: 14px;
  border-radius: 20px;
  margin: 0 5px 0 0;
  border: 1px solid #f39e9a;
}

.one {
  font-weight: 600;
  padding: 1px 10px;
  font-size: 14px;
  border-radius: 20px;
  margin: 0 5px 0 0;
  border: 1px solid #01d16d;
}

.off {
  font-weight: 600;
  padding: 1px 10px;
  font-size: 14px;
  border-radius: 20px;
  margin: 0 5px 0 0;
  border: 1px solid #A5A9A8;
  color: #A5A9A8;
}

.gray {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 0px;
  gap: 10px;
  height: 25px;
  border-radius: 20px;
  color: #53585F;
}

.fixedSizeHeaderClient {
  width: 130px !important;
}

.fixedSizeHeaderTeam {
  width: 60px !important;
}

.fixedSizeHeaderType {
  width: 150px !important;
}

.fixedSizeHeaderMandateName {
  width: 250px !important;
}

.fixedSizeHeaderMd {
  width: 90px !important;
}

.fixedSizeHeaderSm {
  width: 50px !important;
}

.fixedSizeHeaderOwner {
  width: 150px !important;
}

.fixedSizeHeaderStatus {
  width: 140px !important;
}

.black-icons path {
  fill: var(--Noir)!important;
  stroke: var(--Noir) !important;
  stroke-width: 0.03 !important
}