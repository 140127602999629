@mixin __status {
    border-radius: 28px !important;
    padding: 4px 16px;
    cursor: pointer;
    text-transform: capitalize;
    text-align: center;
}

@mixin __status2 {
    @include __status;
    padding: 4px 12px;
    min-width: min-content;
    max-width: max-content;
}

.pending {
    @include __status2;
    color: #eb5d57 !important;
    background-color: transparent;
    border: 1px solid #eb5d57
}

.nty {
    @include __status2;
    background-color: #dbdddf;
    color: #53585f;
}

.eoi {
    @include __status2;
    background-color: #fce7e6;
    color: #851001;
}

.nbi {
    @include __status2;
    background-color: #eb5d57;
    color: white;
}

.nr {
    @include __status2;
    background-color: transparent;
    border: 1px solid #53585f;
    color: #53585f;
}