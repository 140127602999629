.modalInfo {
  background-color: rgba(0, 0, 0, 0.45);
}
.modalDialog {
  max-width: 80%;
  margin-right: 60.5px;
}
.modalContent {
  max-width: max-content;
  margin: 0 auto;
  border: 1px solid #eb5d57;
}
.modalHeader {
  background-color: #eb5d57;
  color: #fff;
  min-width: 26vw;
  .btn-close {
    background: url(../../assets/img/cancel.svg) !important;
    background-repeat: no-repeat !important;
    opacity: 1 !important;
  }
}
.modalFooter {
  justify-content: center;
  border-top: 0px;
}
.contentText {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
}

.buttonSaveDisable {
  background-color: #fce7e6 !important;
  color: #f39e9a !important;
  cursor: default !important;
  &:hover {
    background-color: #fce7e6 !important;
    color: #f39e9a !important;
    cursor: default !important;
  }
}
