@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?9xptwu");
  src: url("../../fonts/icomoon.eot?9xptwu#iefix") format("embedded-opentype"), url("../../fonts/icomoon.ttf?9xptwu") format("truetype"), url("../../fonts/icomoon.woff?9xptwu") format("woff"), url("../../fonts/icomoon.svg?9xptwu#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"],
.slidestory .react-multiple-carousel__arrow--left,
.slidestory .react-multiple-carousel__arrow--left:before,
.slidestory .react-multiple-carousel__arrow--right,
.slidestory .react-multiple-carousel__arrow--right:before,
.hwoyousee .react-multiple-carousel__arrow--left,
.hwoyousee .react-multiple-carousel__arrow--left:before,
.hwoyousee .react-multiple-carousel__arrow--right,
.hwoyousee .react-multiple-carousel__arrow--right:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Team:before {
  content: "\e906";
}

.icon-Counterparties:before {
  content: "\e907";
}

.icon-Dashboard:before {
  content: "\e908";
}

.icon-Data-base:before {
  content: "\e909";
}

.icon-Deals:before {
  content: "\e90a";
}

.icon-Enquiries:before {
  content: "\e90b";
}

.icon-Groups:before {
  content: "\e90c";
}


.icon-Indications:before {
  content: "\e90d";
}

.icon-Legal-Entities:before {
  content: "\e90e";
}

.icon-Log-out:before {
  content: "\e90f";
}

.icon-Vector-Mandate:before {
  content: "\e904";
}


.icon-Participations:before {
  content: "\e911";
}

.icon-People:before {
  content: "\e912";
}

.icon-Pipe:before {
  content: "\e913";
}

.icon-Segregated-Accounts:before {
  content: "\e914";
}

.icon-search:before {
  content: "\e900";
}

.icon-notes:before {
  content: "\e902";
}

.icon-payements:before {
  content: "\e901";
}

.icon-Obligor:before {
  content: "\e905";

}

.icon-Partner:before {
  content: "\e915";
}

.icon-Underwriter:before {
  content: "\e916";

}

.icon-Financiers:before {
  content: "\e917";
}

.icon-Mandates:before {
  content: "\e910";
}

.icon-History:before {
  content: "\e918";
}

.icon-Counterparties1:before {
  content: "\e91a";
}

.icon-Grouppp:before {
  content: "\e92d";
}

.icon-History:before {
  content: "\e918";
}