.dropdownSelect {
  place-items: center !important;
  position: relative !important;

  cursor: pointer !important;
  ;
}

.dropdown {
  z-index: 9999 !important;
  // max-width: 320px !important;
  &::before {
    content: "" !important;
  }
}

.dropdownMenu {
  max-height: 240px !important;
  overflow-y: scroll !important;
  z-index: 9999 !important;
}

.dropdownItem {
  border: 1px solid #f39e9a !important;
  ;
  border-radius: 25px !important;
  ;
  margin-top: 10px !important;
  ;
  font-size: 14px !important;
  ;
  padding-top: 6px !important;
  ;
  padding-bottom: 6px !important;
  ;
  cursor: pointer !important;
  flex-wrap: nowrap !important;
  display: flex !important;
  word-break: break-word !important;

  &:hover {
    background-color: #f39e9a !important;
    ;
    color: white !important;
  }
}

.dropdownItemHovered {
  background-color: #f39e9a;
  color: white;
}

.dropdownToogle {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;

  &::before {
    content: "" !important;
    display: none !important;
  }

  &::after {
    content: "" !important;
    display: none !important;
  }
}

.formInput {
  border: 1px solid #dddddd !important;
  border-radius: 4px !important;
  background-color: #fefefe;
}

.formInputDisabled {
  border: 1px solid #dddddd !important;
  border-radius: 4px !important;
  background-color: #e9ecef;
  user-select: none !important;
}

.formInputError {
  border: 1px solid #ff0505 !important;
}

.formSearchInput {
  border: 1px solid #dddddd !important;
  border-radius: 8px !important;
  background-color: #fefefe;
}

.formInputControl {
  border: none !important;
}

.formSearchInputControl {
  border: none !important;
  border-radius: 8px !important;
}

.iconSearch {
  font-size: 16px;
  color: #f39e9a;
}

.closeIcon {
  color: rgb(109, 18, 6);
  margin-top: 0.7rem;
  cursor: pointer;
}

.closeIconHide {
  color: rgb(109, 18, 6);
  margin-top: 0.7rem;
  cursor: pointer;
  opacity: 0;
}

.toogleButton {
  border: none !important;
  background-color: transparent !important;
  transition: all 0.3s ease-in-out !important;
}

.toogleButtonDisabled {
  border: none !important;
  background-color: #e9ecef !important;
  transition: all 0.3s ease-in-out !important;
}
