.pointer {
  cursor: pointer;
  border-radius: 20px;
  vertical-align: center !important;
  place-items: center;
  align-items: center;
  min-width: 100px;
  user-select: none;
}

@mixin __status {
  border-radius: 28px !important;
  padding: 6px 12px;
  text-transform: capitalize;
  cursor: pointer;
  text-align: center;
}

@mixin __pending {
  @include __status;
  color: #EB5D57 !important;
  text-transform: capitalize !important;
  border: 1px solid #dbdddf;
}

.pending,.nty,.eoi,.nr,.nbi {
  @include __status;
  background-color: transparent;
  color: #a5a9a8;
  border: 1px solid #a5a9a8;
}
.pendingChecked {
  @include __status;
  color: #EB5D57 !important;
  text-transform: capitalize !important;
  background-color: #ffffff;
  border: 1px solid #eb5d57;
}

.ntyChecked {
  @include __status;
  color: #53585F !important;
  text-transform: capitalize !important;
  background-color: #DBDDDF;
}

.eoiChecked {
  @include __status;
  color: #851001 !important;
  text-transform: capitalize !important;
  background-color: #FCE7E6;
}


.nbiChecked {
  @include __status;
  color: #ffffff !important;
  text-transform: capitalize !important;
  background-color: #EB5D57;
}

.nrChecked {
  @include __status;
  color: #53585F !important;
  text-transform: capitalize !important;
  background-color: transparent;
  border: 1px solid #53585F;
}
